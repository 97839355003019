.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.App-content {
  flex: 1;
}

.App-nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.App-nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: center;
}

.App-nav ul li {
  margin: 20px 0;
}

.App-nav ul li a {
  color: white;
  text-decoration: none;
  font-size: 24px;
}

.App-footer {
  background-color: #282c38;
  color: white;
  text-align: center;
  padding: 10px 0;
  width: 100%;
  position: relative;
}

.App-footer-nav {
  list-style-type: none;
  padding: 0;
  margin: 10px 0;
}

.App-footer-nav li {
  display: inline;
  margin-right: 10px;
}

.App-footer-nav li a {
  color: white;
  text-decoration: none;
}

.App-footer-nav li a:hover {
  text-decoration: underline;
}
