.App-footer {
    background-color: #282c38;
    color: white;
    text-align: center;
    padding: 10px 0;
    width: 100%;
    position: relative;
    z-index: 10; /* Ensure footer is above other content */
  }
  
  .App-footer-nav {
    list-style-type: none;
    padding: 0;
    margin: 10px 0;
  }
  
  .App-footer-nav li {
    display: inline;
    margin-right: 10px;
  }
  
  .App-footer-nav li a {
    color: white;
    text-decoration: none;
  }
  
  .App-footer-nav li a:hover {
    text-decoration: underline;
  }
  
  /* Mobile styles */
  @media (max-width: 768px) {
    .App-footer-nav li {
      display: block;
      margin-bottom: 10px;
    }
  }
  