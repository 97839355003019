.App-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #282c38;
  padding: 10px 20px;
}

.App-logo-link {
  display: flex;
  align-items: center;
}

.App-logo {
  width: auto; /* Adjust the size as necessary */
  height: 80px;
}

.menu-icon {
  font-size: 24px;
  cursor: pointer;
}
