.services {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #282c38; /* Match header and footer background color */
  padding: 20px 0; /* Add padding to ensure content is not hidden */
}

.services-content {
  background-color: white;
  color: #000470;
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  max-width: 800px;
  text-align: center;
}
