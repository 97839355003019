.contact-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #282c38;
  color: white;
  padding: 20px;
}

form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: white;
  color: black;
  padding: 20px;
  border-radius: 10px;
}

label {
  margin-bottom: 10px;
}

input, textarea {
  width: 100%;
  margin-top: 5px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

button {
  padding: 10px 20px;
  background-color: #148aff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

#result-text {
  margin-top: 20px;
}
