.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #282c38; /* Match header and footer background color */
  position: relative;
  color: black;
  padding: 20px 0; /* Add padding to ensure content is not hidden */
}

.menu-icon {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 24px;
  cursor: pointer;
}

.home-logo {
  margin-top: 10px;
  margin-bottom: 10px;
}

.home-logo img {
  width: 300px; /* Adjust the size as necessary */
  height: auto;
}

.home-image {
  margin-top: -10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-image img {
  object-fit: contain;
  width: 80%;
  height: auto;
}
